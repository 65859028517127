import http from '@/utils/http';

/**
 * ceo信箱
 */
export default {
  getList: {
    p: 'post,/message/board/getlist',
    r: (data) => http({ url: '/message/board/getlist', method: 'post', data })
  },
  update: {
    p: 'post,/message/board/update',
    r: (data) => http({ url: '/message/board/update', method: 'post', data })
  },
  del: {
    p: 'post,/message/board/delete',
    r: (data) => http({ url: '/message/board/delete', method: 'post', data })
  },
  getOne: {
    p: 'post,/message/board/getone',
    r: (data) => http({ url: '/message/board/getone', method: 'post', data })
  }
};
