import './list.scss';
import dataApi from '@/api/member/board.js';
/*
* CEO信箱
*  */
export default {
  name: 'member-board',
  components: {},
  data() {
    return {
      page: {
        pageSize: 20,
        pageSizes: [20, 30, 40, 50, 100]
      },
      tableData: [],
      tbleOption: {
        total: 20,
        align: 'center',
        cellBtn: false,
        cancelBtn: false,
        ...this.$store.getters.tableConfig,
        addBtn: false,
        viewBtn: true,
        editBtnText: '回复',
        editTitle: 'CEO回复',
        updateBtnTitle: '立即回复',
        column: [
          {
            type: 'select',
            label: '会员',
            search: 'true',
            hide: true,
            props: {
              label: 'label',
              value: 'value'
            },
            dicUrl: `${this.$baseUrl}general/get_member?keywords={{key}}`,
            remote: true,
            dicMethod: 'post',
            prop: 'member_id',
            editDisplay: false,
            viewDisplay: false
          },
          {
            label: '留言会员',
            prop: 'member_name',
            editDetail: true,
            span: 24
          },
          {
            label: '留言内容',
            prop: 'content',
            editDetail: true,
            span: 24
          },
          {
            type: 'upload',
            label: '图片',
            prop: 'images',
            detail: true,
            listType: 'picture-card',
            span: 24
          },
          {
            label: '回复内容',
            prop: 'reply',
            editDisplay: true,
            type: 'textarea',
            span: 24
          },
          {
            type: 'radio',
            label: '状态',
            value: 1,
            dicData: [
              {
                label: '无效',
                value: -1
              },
              {
                label: '提交成功',
                value: 1
              },
              {
                label: '已处理',
                value: 2
              }
            ],
            prop: 'status',
            search: true,
            editDisplay: false
          },
          {
            label: '操作员',
            prop: 'replay_user',
            editDisplay: false,
            viewDisplay: false
          },
          {
            label: '创建时间',
            addDisplay: false,
            editDisplay: false,
            prop: 'create_time'
          }
        ]
      },
      searchData: {}
    };
  },
  computed: {},
  mounted() {},
  filters: {},
  methods: {
    // 分页
    onLoad(page) {
      this.page = page;
      this.getList();
    },
    // 筛选
    searchChange(params, done) {
      this.searchData = params;
      this.page.currentPage = 1;
      this.getList();
      done();
    },
    // 筛选清空
    searchReset() {
      this.page.currentPage = 1;
      this.searchData = {};
      this.getList();
    },
    // 删除
    rowDel(form) {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        console.log('删除数据', form);
        dataApi.del.r({ id: form.id }).then((res) => {
          console.log(res);
          this.getList();
          this.$message.success('删除成功');
        });
      });
    },
    // 修改
    rowUpdate(form, index, done, loading) {
      console.log('index', form, index);
      dataApi.update.r(form).then((res) => {
        console.log(res);
        done(form);
        this.getList();
        loading();
      });
      done();
    },
    // 获取
    getList() {
      const postData = {
        page: this.page.currentPage,
        pageSize: this.page.pageSize,
        ...this.searchData
      };
      dataApi.getList.r(postData).then((res) => {
        console.log('接口返回', res);
        this.tableData = res.data.list;
        this.page.total = res.data.total;
      });
    }
  }
};
